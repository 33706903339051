import { useState, useContext } from "react";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import Dialog from 'react-bootstrap-dialog'

import { AuthContext } from "./Auth/AuthContext";
import CameraDialog from "./CameraDialog";
import eventBus from "./EventBus";

export default function CameraButton(props) {
  const { user } = useContext(AuthContext);
  const [cameraDialogShow, setCameraDialogShow] = useState(false);
  let dialog;
  function showCamera() {
    if (user == null) {
      dialog.show({
        title: 'カメラで手書き解答を文字認識',
        body: '手書きの解答英文をカメラで撮影すると、文字認識をしてテキストを読み込みます。文字認識を始めるには、無料のユーザ登録をしてログインしてください。',
        actions: [
          Dialog.Action(
            'キャンセル',
            () => dialog.hide(),
            'btn-secondary'
          ),
          Dialog.Action(
            'ログイン',
            async () => {
              eventBus.dispatch("login");
            },
            'btn-secondary'
          ),
          Dialog.DefaultAction(
            'ユーザ登録',
            async () => {
              eventBus.dispatch("signup");
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
      return;
    }
    else {
      setCameraDialogShow(true)
    }
  }
  function hideCamera() {
    setCameraDialogShow(false);
  }
  function handleUseResult(text) {
    setCameraDialogShow(false);
    props.handleUseResult(text);
  }
  return (
    <>
      <MDBBtn floating size="sm" className={`green-button ${props.className}`} onClick={showCamera}>
        <MDBIcon fas icon="camera" className="ms-auto" />
      </MDBBtn>
      <CameraDialog show={cameraDialogShow} onHide={hideCamera} onUseResult={handleUseResult} />
      <Dialog ref={(el) => { dialog = el }} />
    </>
  )
}
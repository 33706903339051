import React, { useState, useContext, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

import { AuthContext } from "./Auth/AuthContext";
import eventBus from "./EventBus";

export default function RequestLoginModal(props) {
  const { user } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (user != null) {
      props.onLoggedin(props.onLoggedinAttributes);
    }
    else{
      setShow(props.show);
    }
  }, [props.show, props.onLoggedinAttributes])

  return (
    <MDBModal show={show} setShow={setShow} tabIndex='-1'>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalBody>
            無料のユーザ登録をしてログインすると、全ての文章スタイルをご利用になれます。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color='secondary' size='sm' onClick={props.handleClose}>
              キャンセル
            </MDBBtn>
            <MDBBtn 
              color='secondary' size='sm' 
              onClick={() => {props.handleClose(); eventBus.dispatch("login")}}
            >
              ログイン
            </MDBBtn>
            <MDBBtn 
              color='success' 
              size='sm' 
              onClick={() => {props.handleClose(); eventBus.dispatch("signup")}}
            >
              ユーザ登録
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
import React from "react";
import { 
  MDBContainer, 
  MDBRow, 
  MDBCol, 
  MDBBtn, 
  MDBSpinner, 
  MDBDropdown, 
  MDBDropdownMenu, 
  MDBDropdownToggle,
  MDBDropdownItem, 
  MDBBreadcrumb, 
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';
import copy from 'copy-to-clipboard';
import {Helmet} from "react-helmet";
import TextCorrection from "./english/TextCorrection"
import CameraButton from "./CameraButton";
import { Link } from "react-router-dom";
import * as api from "./api";
import { AuthContext } from "./Auth/AuthContext";
import RequestLoginModal from "./RequestLoginModal";
import * as device from './device';

const Diff = require('diff');

class Content extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    const sampleOrgTxt = "Write you're note heer."
    const sampleCorTxt = "Write your note here."
    const defaultLang = "en"
    this.nonRestrictedStyles = ["standard", "simple"]
    this.state = {
      userContent : sampleOrgTxt,
      org_txt : sampleOrgTxt,
      correctedLang: defaultLang,
      cor_txt : sampleCorTxt,
      style : 'standard',
      lang : defaultLang,
      loading : false,
      requestLoginModalShow: false,
      selectedStyle: 'standard',
    };
  }

  componentDidMount() {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }

  handleContentChange = (event) => {
    this.setState({...this.state,
      userContent : event.target.value,
      loading : false
    });
  }

  handleUseResult = (text) => {
    this.setState({...this.state,
      userContent : text,
      loading : false
    });
  }

  changeStyle = (style) => {
    this.setState({...this.state,
      style : style,
      loading : false,
      requestLoginModalShow: false,
    });
  }

  handleOnCloseRequestLoginModal = () => {
    this.setState({...this.state,
      requestLoginModalShow: false,
    });
  }
  
  handleStyleChange = (event) => {
    if (this.nonRestrictedStyles.includes(event.target.value)) {
      this.changeStyle(event.target.value);
    }
    else{
      this.setState({...this.state,
        requestLoginModalShow: true,
        selectedStyle: event.target.value,
      });
    }
  }

  handleLangChange = (lang) => {
    this.setState({...this.state,
      lang: lang,
    });
  }

  handleCopyResult = (event) => {
    copy(this.state.cor_txt, {format:'text/plain'});
  }

  handleContentSubmit = async (event) => {
    this.setState({...this.state,
      userContent : this.state.userContent,
      org_txt: '',
      cor_txt : '',
      loading : true
    });

    let response
    try {
      response = await api.getCorrection(this.state.userContent, this.state.style, this.state.lang);
    }
    catch (err){
      console.error('Failed to get correction', err);
    }


    this.setState({...this.state,
      org_txt: this.state.userContent,
      cor_txt : response.correction,
      correctedLang: this.state.lang,
      loading : false
    });
  }

  render() {
    return (
      <MDBContainer fluid className="p-md-5 light-green-page">
        <MDBRow center>
          <MDBCol>
            <MDBRow>
              <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <Link to="/">
                    <MDBBtn color='secondary' outline rounded>
                      ホーム
                    </MDBBtn>
                  </Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active className="mt-1">
                  <span className="fw-bold">
                    AI英語添削
                  </span>
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
            </MDBRow>
            <MDBRow className="h5 green-text px-4 py-3">
              あなたの英作文をAIが添削してくれるツールです
            </MDBRow>
            <MDBRow className="px-4 py-3">
              <textarea className="texteditor" id="content" name="content" value={this.state.userContent} onChange={this.handleContentChange} rows={5} />
              {device.isIOSApp() === false && (
                <div className="d-flex justify-content-end camera-button-overlay">
                  <CameraButton className="me-2" handleUseResult={this.handleUseResult} />
                </div>
              )}
            </MDBRow>
            <MDBRow className="px-4 py-3">
              <MDBDropdown dropright id="dropdown-basic-button">
                <MDBDropdownToggle className="green-button">{this.state.lang == 'en' ? 'English' : '日本語（β）'}</MDBDropdownToggle>
                <MDBDropdownMenu className="mx-2">
                  <MDBDropdownItem className={`m-2 ${this.state.lang == 'en' ? 'active' : ''}`} link onClick={() => this.handleLangChange("en")}>English</MDBDropdownItem>
                  <MDBDropdownItem className={`m-2 ${this.state.lang == 'ja' ? 'active' : ''}`} link value="ja" onClick={() => this.handleLangChange("ja")}>日本語（β）</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBRow>
            <MDBRow className="px-4 py-3">
              <MDBCol>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="styles" id="standard" value="standard" onChange={this.handleStyleChange} checked={this.state.style == 'standard'} />
                  <label className="form-check-label" htmlFor="standard">
                    Standard
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="v" id="simple" value="simple" onChange={this.handleStyleChange} checked={this.state.style == 'simple'} />
                  <label className="form-check-label" htmlFor="simple">
                    Simple
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="styles" id="business" value="business" onChange={this.handleStyleChange} checked={this.state.style == 'business'} />
                  <label className="form-check-label" htmlFor="business">
                    Business
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="styles" id="romantic" value="romantic" onChange={this.handleStyleChange} checked={this.state.style == 'romantic'} />
                  <label className="form-check-label" htmlFor="romantic">
                    Romantic
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="styles" id="funny" value="funny" onChange={this.handleStyleChange} checked={this.state.style == 'funny'} />
                  <label className="form-check-label" htmlFor="funny">
                    Funny!
                  </label>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="px-4 py-3">
              <MDBCol>
                <MDBBtn className="text-capitalize btn btn-primary btn-md fw-bold green-button" disabled={!this.state.userContent} onClick={this.handleContentSubmit}>
                  <span>
                    CORRECT IT
                    {this.state.loading &&
                      <MDBSpinner role='status' className="ms-1" style={{ width: '1rem', height: '1rem' }} />
                    }
                  </span>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow className="px-4 py-3">
              <div style={{ display: this.state.cor_txt ? 'block' : 'none' }}>
                <h2 className="header">Correction:</h2>
                <h3>
                  <TextCorrection org_txt={this.state.org_txt} cor_txt={this.state.cor_txt} diff_words={this.state.correctedLang == "en"} />
                </h3>
                <MDBBtn className={`text-capitalize btn btn-primary btn-md fw-bold green-button`} onClick={this.handleCopyResult}>COPY RESULT</MDBBtn>
              </div>
            </MDBRow>
            <MDBRow className="mt-5">
              <div className="h6 fw-bold text-center green-text">
                ＼合格への近道は練習にあり！／
              </div>
            </MDBRow>
            <MDBRow center className='text-center mt-1 pb-5'>
              <Link to="/">
                <MDBBtn size='lg' className="orange-button lh-sm">
                  <div className="strong subtitle">
                    英検<sup>®</sup>ライティング対策
                  </div>
                  <div className="strong subtitle">
                    はこちら
                  </div>
                </MDBBtn>
              </Link>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <RequestLoginModal 
          show={this.state.requestLoginModalShow}
          handleClose={this.handleOnCloseRequestLoginModal}
          onLoggedin={this.changeStyle}
          onLoggedinAttributes={this.state.selectedStyle}
        />
      </MDBContainer>
    );
  }
}

class Correction extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>UGUIS.AI | AI英語添削 | JIEM, Inc.</title>
          <meta
            name="description"
            content="UGUIS.AI | AI英語添削 | JIEM, Inc."
          />
        </Helmet>
        <Content/>
      </div>
    );
  }
}

export default Correction;

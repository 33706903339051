import {React, useState} from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBSpinner,
} from 'mdb-react-ui-kit';

import Modal from 'react-bootstrap/Modal'
import Webcam from "react-webcam";
import * as api from "./api"

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

const WebcamComponent = () => <Webcam />;

export default function CameraDialog(props) {
  const [cameraImage, setCameraImage] = useState(null);
  const [ocrResult, setOcrResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [primaryPaneSize, setPrimaryPaneSize] = useState(0);

  const videoConstraints = {
    // do not specify resolutions. otherwise mobile browsers will return very low resolution
    // width: {min: 3600, ideal: 7200},
    // height: {min: 2400, ideal: 4800},
    facingMode: "environment",
  };

  // const handleDevices = (mediaDevices) => {
  //   mediaDevices.forEach(device => {
  //     console.log(device)
  //     console.log(device.getCapabilities())
  //   })
  // }
  // navigator.mediaDevices.enumerateDevices().then(handleDevices);

  const onPrimaryPaneSizeChange = primaryPaneSize => {
    console.log(primaryPaneSize)
    setPrimaryPaneSize(primaryPaneSize);
  }

  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton className="py-1 m-0">
        <MDBContainer className="m-0">
        <MDBRow center className="h5">答案用紙を撮影します</MDBRow>
        </MDBContainer>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
      {!cameraImage && (
        <div className="h-100 d-flex justify-content-center">
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            screenshotQuality={1}
          >
            {({ getScreenshot }) => (
              <div className="d-flex justify-content-end fixed-bottom mb-3">
                <MDBBtn className="green-button mx-3" onClick={() => {
                  const imageSrc = getScreenshot();
                  // console.log(imageSrc)
                  setCameraImage(imageSrc);
                }}>
                  <MDBIcon fas icon="camera me-2"/>撮影する
                </MDBBtn>
              </div>
            )}
          </Webcam>
        </div>
      )}
      {cameraImage && (
        <>
        <SplitterLayout vertical={true} percentage={true} primaryIndex={0} primaryMinSize={10} secondaryMinSize={10}>
          <div className="h-100 d-flex justify-content-center">
            <img className="img-fluid" src={cameraImage}/>
          </div>
          {ocrResult && (
            <MDBContainer className="p-3 h-100">
              <MDBRow className="d-flex justify-content-center h-100">
                <textarea className="texteditor p-3" value={ocrResult} onChange={(e) => {
                  setOcrResult(e.target.value);
                }}/>
              </MDBRow>
            </MDBContainer>
          )}
        </SplitterLayout>
        <div className="d-flex justify-content-end mb-3 fixed-bottom">
          <MDBBtn className="green-button" onClick={() => {
            setCameraImage(null);
            setOcrResult(null);
          }}>
            <MDBIcon fas icon="camera me-2"/>撮り直し
          </MDBBtn>
          {!ocrResult && (
            <MDBBtn className="green-button mx-3" onClick={async () => {
              setLoading(true);
              const result = await api.ocr(cameraImage);
              setLoading(false);
              setOcrResult(result);
            }}>
              {loading &&
                <MDBSpinner role='status' className="me-2" style={{ width: '1rem', height: '1rem' }} />
              }
              {!loading &&
                <MDBIcon fas icon="align-left me-2"/>
              }
              文字認識する
            </MDBBtn>
          )}
          {ocrResult && (
            <MDBBtn rounded className="green-button mx-3" onClick={() => {
              if (props.onUseResult) {
                props.onUseResult(ocrResult)
              }}}>
              <MDBIcon fas icon="spell-check me-2"/>
              認識結果を使う
            </MDBBtn>
          )}
        </div>
        </>
      )}
      </Modal.Body>
    </Modal>
  );
}

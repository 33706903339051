import { getSession, isSignedIn } from './Auth/auth'
import { Question, OpinionQuestion, EmailQuestion, Assignment } from "./english/model/Assignment";
import {retry} from "./retry";

async function callAuthenticatedAPI(method, url, body=null) {
  const session = await getSession()
  const accessToken = session.tokens.accessToken.toString();
  return await callAPI(method, url, {'Authorization': `Bearer ${accessToken}`}, body)
}

async function callAPI(method, url, headers={}, body=null) {
  const reqOptions = {
    method: method,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: body
  };
  const response = await fetch(url, reqOptions);
  const jsonData = await response.json()
  return { json: jsonData, status: response.status }
}

export async function getUserProfile() {
  const response = await callAuthenticatedAPI('GET', '/api/profile')
  if (response.status == 200) {
    return response.json
  }
  else{
    return null
  }
}

export async function updateAcceptedTosVersion(accepted_tos_version) {
  const response = await callAuthenticatedAPI('PUT', '/api/profile/accepted_tos_version', JSON.stringify({
    "accepted_tos_version": accepted_tos_version,
  }))

  if (response.status == 200) {
    return response.json
  }
  else{
    return false
  }
}

export async function deleteUserAccount(id) {
  const response = await callAuthenticatedAPI('DELETE', `/api/profile`)
  if (response.status == 200) {
    return true
  }
  else {
    return false
  }
}

export async function getUserAssignments() {
  const response = await callAuthenticatedAPI('GET', '/api/assignments')
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getUserAssignment(id) {
  const response = await callAuthenticatedAPI('GET', `/api/assignments/${id}`)
  if (response.status != 200) {
    return null
  }

  const assignment = response.json

  const assignment_data = assignment.assignment_data

  if (assignment_data.question.type == undefined) {
    assignment_data.question.type = assignment.type;
  }

  if (assignment_data.question.grade == undefined) {
    assignment_data.question.grade = assignment.grade;
  }

  return {
    "id": assignment.id,
    "grade": assignment.grade,
    "type": assignment.type,
    "title": assignment.title,
    "score": assignment.score,
    "modified_at": assignment.modified_at,
    "assignment": Assignment.fromJSON(assignment_data)
  }
}

export async function deleteUserAssignment(id) {
  const response = await callAuthenticatedAPI('DELETE', `/api/assignments/${id}`)
  if (response.status == 200) {
    return true
  }
  else {
    return false
  }
}

export async function createUserAssignment(assignment) {
  const response = await callAuthenticatedAPI('POST', `/api/assignments/`, JSON.stringify({
    "grade": assignment.question.grade,
    "type": assignment.question.type,
    "title": assignment.title,
    "score": assignment.score,
    "assignment": assignment
  }))
  if (response.status == 201) {
    const id = response.json
    return id
  }
  else {
    return null
  }
}

export async function updateUserAssignment(id, assignment) {
  await callAuthenticatedAPI('PUT', `/api/assignments/${id}`, JSON.stringify({
    "grade": assignment.question.grade,
    "type": assignment.question.type,
    "title": assignment.title,
    "score": assignment.score,
    "assignment": assignment
  }))
  return true
}

export async function scoreAssignment(assignment) {
  const isLoggedIn = await isSignedIn();
  let response;

  if (isLoggedIn) {
    response = await callAuthenticatedAPI('POST', `/api/score/english`, JSON.stringify(assignment));
  } else {
    response = await callAPI('POST', `/api/score/english`, {}, JSON.stringify(assignment));
  }

  if (response.status == 200) {
    return response.json;
  } else {
    return null;
  }
}

export async function generateQuestion(grade, type, topic){
  const response = await callAuthenticatedAPI(
    'POST',
    `/api/generate/english`,
    JSON.stringify({'grade': grade, 'type': type, 'topic': topic}))
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getSystemConfig() {
  const response = await callAPI('GET', '/api/system_config')
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getCorrection(content, style, lang) {
  let response
  let request_data = {
    content: content,
    style: style,
    lang: lang
  }
  const isLoggedIn = await isSignedIn()
  if (isLoggedIn) {
    response = await callAuthenticatedAPI('POST', `/api/correction`, JSON.stringify(request_data))
  }
  else {
    response = await callAPI('POST', `/api/correction`, {}, JSON.stringify(request_data))
  }

  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export const convertDataURIToBinary = (dataURI) => {
  //Convert base64 to file
  let arr = dataURI.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return u8arr;
}

export async function ocr(imgData) {
  let response = await callAuthenticatedAPI('POST', `/api/ocr`, convertDataURIToBinary(imgData))
  if (response.status == 201) {
    let task_id = response.json;

    let result = await retry(async () => { return await get_ocr_result(task_id) }, 5, 0)
    // console.log('ocr result:', result)
    return result;
  }
  else {
    return null
  }
}

export async function get_ocr_result(task_id) {
  let response = await callAuthenticatedAPI('GET', `/api/ocr/${task_id}`)
  if (response.status == 200) {
    return response.json
  }
  else {
    throw new Error();
  }
}

export async function create_chat() {
  let response = await callAuthenticatedAPI('POST', `/api/chat`)
  if (response.status == 201) {
    let thread_id = response.json;
    return thread_id;
  }
  else {
    return null
  }
}

export async function chat(thread_id, request_data) {
  let response = await callAuthenticatedAPI('POST', `/api/chat/${thread_id}`, JSON.stringify(request_data))
  if (response.status == 201) {
    return response.json;
  }
  else {
    return null
  }
}
export async function delete_chat(thread_id) {
  await callAuthenticatedAPI('DELETE', `/api/chat/${thread_id}`)
}
